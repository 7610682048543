import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DoNotTouchOutlinedIcon from "@mui/icons-material/DoNotTouchOutlined";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  VerticalSplit,
  DownloadOutlined,
  FlipCameraAndroidOutlined,
  PlagiarismOutlined,
} from "@mui/icons-material";
import { imprimir, descargarPdf } from "../../../apis/api";
import { alpha } from "@mui/material/styles";
import EnvMail from "../EnvMail";
import CancelarDoc from "../CancelarDoc";
import InutilizarDoc from "../InutilDoc";
import VerMotivo from "../VerMotivo";
import ConsultaLote from "../ConsultaLote";
import ConsultaDE from "../ConsultaDE";
import { getItemSession, MainUrl, getSession } from "./../../../apis/Session";
import { getConfiguracion, consultaCDC } from "../../../apis/api";
import { toast } from "react-toastify";

const EnhancedTableToolbar = (props) => {
  const { numSelected, selected, setConsulta, consulta, setFiltro } = props;

  const handleClick = () => {
    imprimir(selected.cdc);
  };

  const handleClickD = async () => {
    document.getElementById("descargarPDF").style.display = "none";
    document.getElementById("esperar").style.display = "block";
    await descargarPdf(selected.cdc);
    document.getElementById("esperar").style.display = "none";
    document.getElementById("descargarPDF").style.display = "block";
  };

  const uri = `${MainUrl}rest/descargaXML?ruc=${getItemSession("ruc")}&codigo=${
    selected.cdc
  }`;

  const [open, setOpen] = React.useState(false);
  const [openCancel, setCancelOpen] = React.useState(false);
  const [openInut, setInutOpen] = React.useState(false);
  const [openMot, setMotOpen] = React.useState(false);
  const [openClt, setCltOpen] = React.useState(false);
  const [openCde, setCdeOpen] = React.useState(false);
  const enviarCorreo = () => {
    setOpen(true);
  };

  const cancelarDocu = () => {
    setCancelOpen(true);
  };

  const inutilizarDoc = () => {
    setInutOpen(true);
  };

  const verMotivo = () => {
    setMotOpen(true);
  };

  const consultaLote = () => {
    setCltOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelClose = () => {
    setCancelOpen(false);
  };

  const handleInutClose = () => {
    setInutOpen(false);
  };

  const handleMotClose = () => {
    setMotOpen(false);
  };

  const handleCltClose = () => {
    setCltOpen(false);
    setConsulta(!consulta);
  };

  const handleCdeClose = () => {
    setCdeOpen(false);
    setConsulta(!consulta);
  };

  const consultar = async () => {
    setCdeOpen(true);
    let resp = await consultaCDC(selected.cdc);
    if (resp !== "" && resp !== undefined) {
      // if (resp.length > 2) {
      resp =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(resp));
      let downloadNode = document.createElement("a");
      downloadNode.setAttribute("href", resp);
      downloadNode.setAttribute("download", "Consulta_CDC.json");
      document.body.appendChild(downloadNode); // required for firefox
      downloadNode.click();
      downloadNode.remove();
      handleCdeClose();
      return toast.success(
        `Consulta de CDC ${selected.cdc} realizada exitosamente`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      toast.error(`No fue posible consultar el documento ${selected.cdc}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCdeClose();
    }
  };

  const [configuracion, setConfiguracion] = useState({
    ruc: "",
    certificado: "",
    idCsc: "",
    csc: "",
    usuCert: "",
    passCert: "",
    aliasCert: "",
    logo: "",
    mailurl: "https://factemail.facturafacil.com.py/enviar",
    mailhost: "",
    mailport: "",
    mailport2: "",
    protocol: "",
    fallback: "",
    starttls: "",
    ssl: "",
    mail: "",
    mailpass: "",
    mailsubject: "",
    mailbody: "",
    server: "",
    tipserv: "",
    ws_server: "",
    timbrado: "",
    establecimiento: "",
    expedicion: "",
    vigenciaini: "",
    kude: "",
  });

  useEffect(async () => {
    let config = await getConfiguracion();
    setConfiguracion(config);
  }, []);

  useEffect(() => {
    setFiltro({
      token: getSession().token,
      ruc: getSession().ruc,
      cdc: "",
      lote: "",
      fecha: "",
      fecini: "",
      fecfin: "",
      envmail: "",
      nombre: "",
      suc: "",
      exp: "",
      nrocmp: "",
      tipcmp: "",
      condicion: "",
      limit: 80,
      offset: 0,
      orden: "fecha",
      tipOrden: "desc",
    });
  }, [open]);

  return (
    <React.Fragment>
      {getItemSession("role") === "Auditoria" ? (
        <>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
            style={{ justifyContent: "end" }}
          >
            {/* <Tooltip title="Descargar Documento"> */}
            <>
              <a id="esperar" style={{ display: "none", marginRight: "10px" }}>
                <HourglassTopIcon style={{ fontSize: "2rem" }} />
              </a>
              <a id="descargarPDF" onClick={handleClickD}>
                <IconButton style={{ marginRight: "10px" }}>
                  <DownloadOutlined style={{ fontSize: "2rem" }} />
                </IconButton>
              </a>
            </>
            {/* </Tooltip> */}
          </Toolbar>
        </>
      ) : (
        <>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
                style={{ textAlign: "left" }}
              >
                {numSelected} documento seleccionado {selected.cdc} / Lote{" "}
                {selected.lote}
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
                style={{ textAlign: "left" }}
              >
                Seleccione documento
              </Typography>
            )}

            {numSelected > 0 ? (
              <React.Fragment>
                <Tooltip title="Consultar CDC">
                  <a onClick={consultar}>
                    <IconButton>
                      <PlagiarismOutlined />
                    </IconButton>
                  </a>
                </Tooltip>
                <Tooltip title="Consultar Lote">
                  <a onClick={consultaLote}>
                    <IconButton>
                      <FlipCameraAndroidOutlined />
                    </IconButton>
                  </a>
                </Tooltip>
                <Tooltip title="Descargar XML">
                  <a href={uri}>
                    <IconButton>
                      <DownloadOutlined />
                    </IconButton>
                  </a>
                </Tooltip>
                <Tooltip title="Imprimir">
                  <IconButton onClick={handleClick}>
                    <LocalPrintshopOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Enviar Correo">
                  <IconButton onClick={enviarCorreo}>
                    <EmailOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancelar Documento">
                  <IconButton onClick={cancelarDocu}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {selected.estado === "Rechazado" ? (
                  <Tooltip title="Motivo Rechazado">
                    <IconButton onClick={verMotivo}>
                      <VerticalSplit />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Tooltip title="Inutilizar Documentos">
                  <IconButton onClick={inutilizarDoc}>
                    <DoNotTouchOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            )}
          </Toolbar>
          <EnvMail
            open={open}
            handleClose={handleClose}
            selected={selected}
            configuracion={configuracion}
          />
          <VerMotivo
            open={openMot}
            handleClose={handleMotClose}
            selected={selected}
          />
          <ConsultaLote
            open={openClt}
            handleClose={handleCltClose}
            selected={selected}
          />
          <ConsultaDE open={openCde} handleClose={handleCdeClose} />
          <CancelarDoc
            open={openCancel}
            handleClose={handleCancelClose}
            selected={selected}
          />
          <InutilizarDoc
            open={openInut}
            handleClose={handleInutClose}
          ></InutilizarDoc>
        </>
      )}
    </React.Fragment>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
